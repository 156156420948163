<template>
    <div class="fino-container">
        <div class="finotech">
            <h2> شناسایی دو عاملی فینوتک </h2>
            <h4> کد ۵ رقمی ارسال شده به شماره خود را وارد نمایید </h4>



            <div class="label-container">
                <div class="input div-active">
                    <label for="input">
                        <span class="span-active"> کد ملی </span>
                        <input disabled v-model="state.userInfo.nationalCode" type="text">
                    </label>
                </div>
            </div>



            <div class="label-container">
                <div class="input div-active">
                    <label for="input">
                        <span class="span-active"> تلفن همراه </span>
                        <input disabled v-model="state.userInfo.mobileNumber" type="text">
                    </label>
                </div>
            </div>


            <label @click="blanckSpace=true" for="">
                <CodeInput :fields="5"  :loading="false" class="verif-code" @change="onChange" @complete="submit" />
            </label>



            <div class="btns">
                <button @click.prevent="submit" :disabled="otp.code.length !== 6" class="submit"> ثبت </button>
                <button disabled v-if="countDown !== 0" class="counter">{{countDown}} ثانیه </button>
                <button @click.prevent="send" v-else class="counter"> ارسال مجدد کد </button>
            </div>
        </div>
    </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";
    export default {
        name:'FinoTech',
        props:['',''],
        components:{
            CodeInput
        },
        data(){
            return{
                fields: {
                type: Number,
                default: 5
                },
                blanckSpace:false,
                countDown:120,
                timeOut:'',
                otp: {
                code: ''
                },
            }
        },
        methods:{
        countDownTimer() {
            if (this.countDown > 0) {
                this.timeOut = setTimeout(() => {
                    this.countDown--
                    this.countDownTimer()
                }, 1000)
            } else {
                if(this.state.lan=='Fa'){
                    this.$error('زمان شما به اتمام رسید', '')
                } else {
                    this.$error('Your time is up', '')
                }
            }
        },
        async send() {
            // this.reset()
            this.state.loading = true
            const res = await this.$axios.get(`/finotech/send-sms-to-customer?email=${this.state.userInfo.email}`)
            if (res.message === 'Sms sent to customer.') {
                if (this.countDown === 0) {
                    this.countDown = 120
                    this.countDownTimer()
                }
            }
        },
        onChange(e) {
            this.otp.code = e
        },
        async submit() {
            this.state.loading = true
            if (this.otp.code.length === 5 || this.loading) {
                this.loading = true
                let [res, ] = await this.$http.get('/finotech/otp', {
                    params: {
                        otp: this.otp.code,
                        email: this.state.userInfo.email
                    }
                })
                this.loading = false
                if (res) {
                    await this.$error('', 'فرایند احراز هویت انجام شد. منتظر تایید اطلاعات توسط پشتیبانی باشید.', 'success')
                    this.$router.push({
                        name: 'Validation'
                    })
                }
            }
            this.state.loading = false
        },
        },
        mounted(){
            this.send();
        },
        created() {
            this.countDownTimer()
        },
        beforeDestroy() {
            clearTimeout(this.timeOut)
        },

    }
</script>

<style lang="scss" scoped>
.btns{
    width: 100%;
    button{
        width: 100%;
    }
}
 h4{
    color: var(--black);
}
.label-container{
    width: 100%;
}
.fino-container{
    align-self: center;
    justify-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .finotech{
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
    }
}

</style>